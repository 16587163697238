export const portfolioList = [
  {
    name: "Satellitez",
    url: "https://www.satellitez.co.uk/",
    github: "https://github.com/snowdog773/satellitez",
    backendGithub: "https://github.com/snowdog773/satellitez-backend",
    description:
      "Satellitez is a web application developed for sky watchers, astronomy enthusiasts, satNOGs operators, and all other common or garden space nerds to access quick and easy information in real time about the location of all the man made objects in orbit currently being tracked by Norad. ",
    image: "satellitez.png",
    tags: [
      "HTML",
      "CSS",
      "Javascript",
      "React",
      "Redux",
      "Node",
      "Express",
      "Three.js",
      "MongoDB",
    ],
  },
  {
    name: "Hard Hangman",
    url: "https://hard-hangman.pitans.co.uk/",
    github: "https://github.com/snowdog773/hangman",
    backendGithub: "https://github.com/snowdog773/scoreboard-server",
    description:
      "Hangman with only hard words built in React with Redux toolkit, based off a random word API. Backend hosts a scoreboard on a MongoDB database.",
    image: "hangman.png",
    tags: [
      "HTML",
      "CSS",
      "Javascript",
      "React",
      "Redux",
      "Node",
      "Express",
      "Canvas",
      "MongoDB",
    ],
  },

  {
    name: "GTW Hypnotherapy",
    url: "https://www.gtw-hypnotherapy.co.uk",
    github: "https://github.com/snowdog773/gtw-hypnotherapy",

    description:
      "Responsive website designed and built in React to a client specification.",
    image: "gtw.png",
    tags: ["HTML", "CSS", "Javascript", "React", "React Router"],
  },
  {
    name: "Blackjack",
    url: "https://pitans-blackjack.netlify.app/",
    github: "https://github.com/snowdog773/blackjack",

    description:
      "Classic card game recreated in React. Player vs an AI dealer.",
    image: "blackjack-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React"],
  },
  {
    name: "20 Squared",
    url: "https://20squared.pitans.co.uk",
    github: "https://github.com/snowdog773/react-pixel-artist",
    backendGithub: "https://github.com/snowdog773/backend-pixel-artist",
    description:
      "A fun pixel art game built in React with an Express and MySQL back end.",
    image: "20squared-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React", "Node", "mySQL", "Express"],
  },
  {
    name: "CAGED - Climate and Geological Events Dispatch",
    url: "https://climate-and-geological-event-dispatch.netlify.app/",
    github: "https://github.com/snowdog773/react-eonet",
    backendGithub: "",
    description:
      "Visualisation of extreme climate and geological events based on data taken from NASA's EONET API service.",
    image: "caged-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React", "Material UI"],
  },
  {
    name: "Russell's Anti Divorce Timer",
    url: "https://pitans-hackathon-divorce-timer.netlify.app/",
    github: "https://github.com/snowdog773/ch9hackathon",
    backendGithub: "",
    description:
      "The result of an 8 hour group hackathon from a brief to create a website to reduce the divorce rate.",
    image: "hackathon-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React"],
  },
  {
    name: "Weather In Your Location",
    url: "https://jon-pitans-weather-api-project.netlify.app/",
    github: "https://github.com/snowdog773/weaather-api-project",
    backendGithub: "",
    description:
      "Using Google location services and a weather API to return weather results in your location.",
    image: "weather-screenshot.png",
    tags: ["HTML", "CSS", "Javascript"],
  },
  {
    name: "The Simpsons Quote-o-Matic",
    url: "https://simpsons-quote-o-matic.netlify.app/",
    github: "https://github.com/snowdog773/js-homework-simpsons",
    backendGithub: "",
    description:
      "Displays Simpsons quotes sourced from an API and also uses some basic React state management to log likes and dislikes.",
    image: "simpsons-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React"],
  },
  {
    name: "The Birthday Countdown-o-Matic",
    url: "https://birthday-o-matic.netlify.app",
    github: "https://github.com/snowdog773/react-birthday-app",
    backendGithub: "",
    description:
      "Timer that counts down to your next birthday. Also resolves API data of famous people who share your birthday.",
    image: "birthday-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React"],
  },
  {
    name: "Tic Tac Toe",
    url: "https://pitans-tictactoe.netlify.app/",
    github: "https://github.com/snowdog773/tictactoe",
    backendGithub: "",
    description:
      "Tic tac toe or noughts and crosses if you prefer, recreated in React.",
    image: "tictactoe-screenshot.png",
    tags: ["HTML", "CSS", "Javascript", "React"],
  },
  // {
  //   name: "Rage Against The Machine Website Homage",
  //   url: "https://jon-pitans-ratm-homage.netlify.app/",
  //   github: "https://github.com/snowdog773/ratm-homage",
  //   backendGithub: "",
  //   description:
  //     "Technical exercise to create a copy of an established website.",
  //   image: "ratm-screenshot.png",
  //   tags: ["HTML", "CSS"],
  // },
  {
    name: "Clipboard - A History of Everything You Copy",
    url: "https://jon-pitans-clipboard-homework.netlify.app/",
    github: "https://github.com/snowdog773/clipboard",
    backendGithub: "",
    description:
      "Technical exercise to create a responive and pixel perfect website based on a plan supplied in a Figma file.",
    image: "clipboard-screenshot.png",
    tags: ["HTML", "CSS", "Figma"],
  },
  {
    name: "Pringles Logo in SVG",
    url: "https://homework-0502-svg-logo.netlify.app/",
    github: "",
    backendGithub: "",
    description:
      "Technical exercise to recreate a well known brand logo using hand coded SVG",
    image: "svg-pringle-screenshot.png",
    tags: ["HTML", "CSS", "SVG"],
  },
];
